
 // 设置缓存，key为缓存名称，value为值，expire为缓存有效时间（单位秒）
export function set(key, value, expire = null){
    // 不设置有限期
    if (!expire) {
        localStorage.setItem(key, JSON.stringify(value));
        return;
    }

    // 计算缓存到期时间
    let cacheExpire = new Date().getTime() + expire * 1000;
    localStorage.setItem(key, JSON.stringify({ value: value, expire: cacheExpire }));
}

// 获取缓存，如果缓存不存在或已过期，返回null
export function get(key) {
    let cache = localStorage.getItem(key);
    if (cache) {
        let cacheObj = JSON.parse(cache);
        if (!cacheObj) {
            return null;
        }
        if (!cacheObj.expire) {
            return cacheObj;
        }
        if (new Date().getTime() > cacheObj.expire) 
        {  // 缓存已过期
            localStorage.removeItem(key);
            return null;
        } else {
            return cacheObj.value;
        }
    } 
    else 
    {
        return null;
    }
}

// 清空
export function clear(key) {
    localStorage.setItem(key, null);
}