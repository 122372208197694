import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/easyflow/HomeView.vue'
import AppView from '../views/app/AppView.vue'
import LoginView from '../views/LoginView.vue'
import AppRegister from '../views/AppRegister.vue'
import AppGeneratorKey from '../views/AppGeneratorKey.vue'
import { get } from '@/utils/local'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'login',
        component: LoginView
    },
    {
        path: '/login',
        name: 'login',
        component: LoginView
    },
    {
        path: '/app-register',
        name: 'app-register',
        component: AppRegister
    },
    {
        path: '/app-generator-key',
        name: 'app-generator-key',
        component: AppGeneratorKey
    },
    {
        path: '/index/:id?',
        name: 'index',
        component: AppView,
        children: [{
            path: 'app/:id',
            component: () => import('../views/app/App.vue')
        },{
            path: 'report/:id',
            component: () => import('../views/report/ReportAppView.vue')
        }, {
            path: '/',
            component: () => import('../views/app/AppGuest.vue')
        }]
    },
    {
        path: '/home',
        name: 'home',
        component: HomeView,
        children:[{
            path: '/',
            component: () => import('../views/easyflow/HomeIndex.vue')
        }, {
            path: 'create',
            component: () => import('../views/easyflow/createapp/CreateApp.vue')
        }, {
            path: 'authconfig/:id?',
            component: () => import('../views/easyflow/authconfig/AuthConfig.vue')
        }, {
            path: 'datasource',
            component: () => import('../views/easyflow/datasource/DataSource.vue')
        }, {
            path: 'report-design',
            component: () => import('../views/easyflow/dev-report-design/DevReportDesign.vue')
        }, {
            path: 'dic',
            component: () => import('../views/easyflow/dic/Dic.vue')
        }, {
            path: 'store',
            component: () => import('../views/easyflow/flowapp/FlowApp.vue')
        },{
            path: 'report',
            component: () => import('../views/easyflow/report/AppReport.vue')
        }, {
            path: 'form-list',
            component: () => import('../views/easyflow/forms/FormList.vue')
        }, {
            path: 'form-design',
            component: () => import('../views/index/Home.vue')
        }, {
            path: 'app/:id?',
            component: () => import('../views/easyflow/app/AppList.vue')
        }, {
            path: 'org-struct',
            component: () => import('../views/easyflow/orgstruct/OrgStruct.vue')
        }, {
            path: 'setting',
            component: () => import('../views/easyflow/settings/SysSetting.vue')
        }, {
            path: 'work',
            component: () => import('../views/easyflow/workers/Workers.vue')
        }]
    },
    {
        path: '/about',
        name: 'about',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach((to, from, next) => {
    // 获取用户信息
    let userInfo = get('user');
    console.log('beforeEach');
    if (!userInfo) {
        if (to.path == '/login') {
            next();
        } else if (to.path == '/app-register') {
            next();
        } else {
            next('/login');
        }
        next();
    } else {
        // 有信息，正常跳转路由
        if (to.path == '/app-generator-key') {
            if (!userInfo || !userInfo.roles || !userInfo.roles.includes('sadmin')){
                next(from.path);
            } else {
                next();
            }
        } else if( to.path.startsWith('/home')) {
            if (!userInfo || !userInfo.roles || (!userInfo.roles.includes('admin') && !userInfo.roles.includes('应用管理员'))){
                next(from.path);
            } else {
                next();
            }
        } else {
            next();
        }
    }
})

export default router
