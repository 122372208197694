/* eslint-disable vue/multi-word-component-names */
import Vue from 'vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import App from './App.vue'
import router from './router'
import '@/styles/index.scss'
import '@/icons'
import axios from 'axios'
import Tinymce from '@/components/tinymce/index.vue'
import request from '@/utils/request.js';
import {formatDate} from '@/filters/dateformat';

// import "ace-builds/css/ace.css";
// import "ace-builds/css/theme/dreamweaver.css";
// import "ace-builds/css/theme/ambiance.css"; 
import "devextreme/dist/css/dx.light.css";
import "@devexpress/analytics-core/dist/css/dx-analytics.common.css";
import "@devexpress/analytics-core/dist/css/dx-analytics.light.css";
import "@devexpress/analytics-core/dist/css/dx-querybuilder.css";
import "devexpress-reporting/dist/css/dx-webdocumentviewer.css";
import "devexpress-reporting/dist/css/dx-reportdesigner.css";

Vue.component('tinymce', Tinymce)

Vue.config.productionTip = false
Vue.prototype.$axios = axios
Vue.prototype.$http = request;

Vue.use(ElementUI);
Vue.filter('formatDate', formatDate);

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
