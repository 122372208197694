<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>

export default {
  name: 'App',
  components: {

  },
  mounted(){
    // if (localStorage.getItem('rg') != '406') {
    //   this.$router.push('/login');
    // } else {
    //this.$router.push('/app-register')
    // }
  }
}
</script>
<style>
#app {
  width: 100%;
  height: 100%;
}
body {
  margin: 0;
  padding: 0;
}
</style>
