import authrequest from '@/utils/authrequest'
import request from '@/utils/request'

export function getMenu(){
    return authrequest({
        url: '/api/app/app/menu-list',
        method: 'get'
    })
}

// 获取指定菜单配置
export function getMenuConfig(id){
    return authrequest({
        url: '/api/app/app-form/' + id + '/config',
        method: 'get'
    })
}

// 添加表单数据
export function saveFormData(data){
    return authrequest({
        url: '/api/app/app-form/save',
        method: 'post',
        data
    })
}

// 获取表单数据
export function getFormDataList(data){
    return authrequest({
        url: '/api/app-form',
        method: 'post',
        data
    })
}

// 获取表单数据
export function getFormDataJoinList(data){
    return authrequest({
        url: '/api/app-form/by-join',
        method: 'post',
        data
    })
}

// 获取表单数据-单个
export function getFormData(params){
    return authrequest({
        url: '/api/app/app-form/by-id',
        method: 'get',
        params
    })
}

// 获取表单数据-删除
export function deleteFormData(params){
    return authrequest({
        url: '/api/app/app-form',
        method: 'delete',
        params
    })
}

// 注册
export function appRegister(data){
    return authrequest({
        url: '/api/app/app-register/register',
        method: 'post',
        params: {
            input: data
        }
    })
}

// 注册
export function getRegister(){
    return authrequest({
        url: '/api/app/app-register',
        method: 'get'
    })
}

// 获取配置
export function getSetting(){
    return authrequest({
        url: '/api/app/setting/info',
        method: 'get'
    })
}

// 获取配置
export function updateSetting(data){
    return authrequest({
        url: '/api/app/setting',
        method: 'put',
        data
    })
}

// Uid
export function getUid(){
    return request({
        url: '/api/app/app-register/uid',
        method: 'get'
    })
}

// 注册
export function getKey(params){
    return authrequest({
        url: '/api/app/app-register/key',
        method: 'get',
        params
    })
}