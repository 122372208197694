import axios from 'axios';
import router from "@/router";
import { Message } from 'element-ui';

let baseUrl = process.env.VUE_APP_BASE_URL;

if (process.env.NODE_ENV === 'production') {
    baseUrl = window.globalConfig.rootUrl;
}

// 创建请求服务
const serves = axios.create({
    baseURL: baseUrl,
    timeout: 30000 // 请求超时时间
})

// 设置请求发送之前的拦截器
serves.interceptors.request.use(config => {
    return config
}, err => {Promise.reject(err)})

// 设置响应拦截器
serves.interceptors.response.use(response => {
    // 根据响应状态码判断是否请求成功
    console.log(JSON.stringify(response));
    if (response.status === 200 || response.status === 204) {
        return Promise.resolve(response.data)
    } else {
        // 如果请求失败，则统一处理错误
        return Promise.reject(response)
    }
}, err => {
    // 取出状态码和消息
    console.log(JSON.stringify(err));
    let message = '';
    let status = err.response && err.response.status ? err.response.status : 401;
    let msg = err.response.data.error ? (
        err.response.data.error.details ? err.response.data.error.details :
        err.response.data.error.message ? err.response.data.error.message :
        err.response.data.error_description ? err.response.data.error_description :
        status + '错误') : status + '错误';

    // 判断请求时发生的错误类型，处理不同的错误
    switch (status) {
        case 400:
            message = msg ? msg : '访问失效，请重新登陆';
            break;
        case 401:
            message = '无访问权限';
            break;
        case 406:
            message = '应用未激活或已过有效期';
            router.push('/app-register');
            break;
        case 403:
            message = msg ? msg :'请求错误';
            break;
        case 415:
            message = '参数格式错误';
            break;
        case 500:
            message = '接口发生错误';
            break;
        default:
            message = msg ? msg : `请求错误`;
            break;
    }
    Message.error(message);
    return Promise.reject(message)
})

export { serves as default, baseUrl }